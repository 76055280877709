import { useState, useEffect } from 'react';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import InputMask from '@mona-health/react-input-mask';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Cookies } from 'react-cookie';
import logo from '../../assets/images/big_logo.jpg';
import Spinner from "../spinner/Spinner";
import './loginForm.scss';

const cookies = new Cookies();

const LoginForm = () => {
    const [status, setStatus] = useState();
    const send = async (data) => {
        setStatus('pending')
        let apiResponse = await fetch(`${process.env.REACT_APP_API_URL}/auth`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                "X-CSRFToken": cookies.get('csrftoken')
            },
        });
        if (apiResponse.status !== 200) {
            setStatus('error');
        } else {
            const apiData = await apiResponse.json();
            const apiKey = apiData.key;
            data.app_key = apiKey;
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const raw = JSON.stringify(data) 
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            fetch("https://new.playmorego.org/api/v1/auth", requestOptions)
                .then((response) => response.json())
                .then((userData) => {
                    const user = JSON.stringify({
                        playmorego_id: userData.id,
                        first_name: userData.first_name,
                        last_name: userData.last_name,
                        email: data.email
                    });
                    const headers = new Headers();
                    headers.append("Content-Type", "application/json");
                    headers.append('X-CSRFToken', cookies.get('csrftoken'))
                    const requestOptions = {
                        method: "POST",
                        headers,
                        body: user,
                        redirect: "follow"
                    };
                    fetch(`${process.env.REACT_APP_API_URL}/auth`, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            setStatus('success');
                        })
                        .catch((error) => {
                            setStatus('error')
                        });
                })
                .catch((error) => {
                    setStatus('error')
                });
            }
    };

    useEffect(() => {
        if (status === 'success') {
            window.location.reload();
        }
    }, [status])


    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validationSchema={Yup.object({
                email: Yup.string()
                    .required('Это обязательное поле')
                    .email('Некорректно введен email'),
                password: Yup.string()
                    .required('Это обязательное поле'),
            })}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
                send({ ...values });
            }}>
            {({ values, setFieldValue }) => (
                <Form className="test__form">
                    <h1 className='test__login-title'>Войдите в аккаунт Playmorego, <br /> чтобы продолжить</h1>
                    <div className='test__body'>
                        <div className="test__fields">
                            <div>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Электронная почта"
                                    defaultValue=""
                                    sx={{ mb: 2 }}
                                    placeholder="example@mail.ru"
                                    onChange={e => {
                                        const value = e.target.value || '';
                                        setFieldValue('email', value);
                                    }}
                                />
                                <ErrorMessage className='test__input-error' component="div" name="email" />
                            </div>
                            <div>
                                <TextField
                                    id="password"
                                    name="password"
                                    label="Пароль"
                                    defaultValue=""
                                    sx={{ mb: 2 }}
                                    onChange={e => {
                                        const value = e.target.value || '';
                                        setFieldValue('password', value);
                                    }}
                                />
                                <ErrorMessage className='test__input-error' component="div" name="name" />
                            </div>
                        </div>
                    </div>
                    {
                        status === 'error' ?
                            <div className='test__result'>При отправке данных произошла ошибка. Перезагрузите страницу и попробуйте еще раз.</div>
                        : null
                    }
                    <div>
                        {
                            status === 'pending' ? (
                                <div className="test__spinner">
                                    <Spinner />
                                </div>
                            ) : (
                                <button type="submit" className="modal__submit">
                                    Войти
                                </button>
                            )
                        }
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default LoginForm;